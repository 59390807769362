<template>
  <el-dialog
      class="overflow-auto"
      title="设置角色"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      @close="close"
      top="5vh"
      width="30%"
  >
    <el-form
        ref="form"
        :model="roleObj"
        label-width="80px"
        label-position="left"
        size="small"
        :rules="rules"
    >
      <el-form-item label="角色名称" prop="name" style="width: 100%">
        <el-input v-model="roleObj.name"></el-input>
      </el-form-item>
    </el-form>
    <div class="w-full">功能权限</div>
    <div class="my-4">
      <div v-for="(item, index) in menuList" :key="item.id" class="my-4">
        <el-checkbox
            :indeterminate="item.indeterminate"
            v-model="item.selectAll"
            @change="selectAllCheckChange(item, index, $event)"
        >
          <span class="font-bold">{{ item.name }}</span>
        </el-checkbox>
        <el-checkbox-group v-model="item.roleGroup">
          <!--        v-for="(item) in menuList" :key="item.id"-->
          <div class="my-2">
            <el-checkbox
                v-for="(child, childIndex) in item.childMenuList"
                :label="child.auth"
                :key="child.auth"
                @change="childCheckChange(item, index, child, childIndex, $event)"
            >{{ child.name }}
            </el-checkbox>
          </div>
        </el-checkbox-group>
      </div>
    </div>

    <div slot="footer" class="flex justify-end">
      <el-button @click="close">关闭</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </div>
  </el-dialog>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">

export default {
  data () {
    return {
      dialogVisible: false,
      roleObj: {},
      menuList: [],
      rules: {
        name: [
          {
            required: true,
            message: '请输入角色名称',
            trigger: 'blur'
          }
        ]
      },
      clean: 0
    }
  },
  methods: {
    close () {
      this.initData()
      this.dialogVisible = false
      this.menuList = []
    },
    async show () {
      await this.getSysMenu()
      return new Promise(resolve => {
        this.initData()
        this.dialogVisible = true
        this.$nextTick(() => {
          this.$refs.form.resetFields()
          resolve()
        })
      })
    },
    async edit (item) {
      await this.show()
      const {
        id,
        name,
        authList
      } = JSON.parse(JSON.stringify(item))
      this.roleObj = {
        id,
        name,
        authList
      }
      this.handlerAuthList(this.roleObj.authList)
    },
    handlerAuthList (authList) {
      this.menuList.forEach(item => {
        item.childMenuList.forEach(child => {
          if (authList.indexOf(child.auth) > -1) {
            item.roleGroup.push(child.auth)
          }
        })
        this.childCheckChange(item)
      })
    },
    initData () {
      this.roleObj = {
        name: ''
      }
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const authList = this.menuList.reduce((prev, cur, index) => {
            if (cur.roleGroup.length > 0) {
              prev = [...prev, ...cur.roleGroup]
            }
            return prev
          }, [])
          this.roleObj.authList = authList
          this.$api.save('sysRole', this.roleObj).then(res => {
            this.close()
            this.$emit('on-submit')
          })
        }
      })
    },
    getSysMenu () {
      return new Promise(resolve => {
        this.$api.findAll('sysMenu').then(res => {
          this.menuList = res.data.map(item => {
            item.indeterminate = false
            item.selectAll = false
            item.roleGroup = []
            return item
          })
          resolve()
        })
      })
    },
    selectAllCheckChange (item, index, $event) {
      if ($event) {
        item.roleGroup = item.childMenuList.map(childItem => childItem.auth)
      } else {
        item.roleGroup = []
      }
    },
    childCheckChange (item, index, child, childIndex, $event) {
      if (item.roleGroup.length === item.childMenuList.length) {
        // 全选
        item.selectAll = true
        item.indeterminate = false
      } else if (item.roleGroup.length > 0 && item.roleGroup.length !== item.childMenuList.length) {
        // 有选项
        item.indeterminate = true
        item.selectAll = false
      } else {
        // 未选择
        item.selectAll = false
        item.indeterminate = false
      }
    }
  },
  mounted () {
  }
}
</script>
