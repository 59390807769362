<!-- 角色权限 -->
<template>
  <div>
    <blockTitle title="角色权限"></blockTitle>
    <el-card shadow="never">
      <div class="card-content">
        <AppTable
            ref="AppTable"
            controller="sysRole"
            :tableColumns="roleManageColumns"
            :request-params="requestParams"
            @on-handleSelection-change="onHandleSelectionChange"
        >
          <template #quick-operator-box>
            <el-button type="primary" size="mini" @click="addRole"
            >创建角色
            </el-button>
            <template class="ml-4" v-if="multipleSelection.length > 0">
              <el-button type="danger" size="mini" @click="deleteRole"
              >删除角色
              </el-button>
            </template>
          </template>
          <template #operator="{ row }">
            <el-button size="small" @click="editRole(row)"> 编辑</el-button>
            <!--            <el-dropdown size="small" split-button @click="editRole(row)">-->
            <!--              编辑-->
            <!--            </el-dropdown>-->
          </template>
        </AppTable>
      </div>
    </el-card>
    <editRoleDialog
        ref="editRoleDialog"
        @on-submit="reGetData"
    ></editRoleDialog>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import editRoleDialog from 'components/sysUser/editRoleDialog'

export default {
  components: {
    editRoleDialog
  },
  data () {
    return {
      roleManageColumns: [
        {
          label: '#',
          type: 'selection'
        },
        {
          label: '角色名称',
          prop: 'name',
          search: true
        },
        {
          label: '创建人',
          prop: 'createName'
        },
        {
          label: '创建时间',
          prop: 'createDtm'
        },
        {
          label: '#',
          type: 'customSlot',
          slotName: 'operator'
        }
      ],
      requestParams: {},
      multipleSelection: []
    }
  },
  methods: {
    onHandleSelectionChange (list) {
      this.multipleSelection = list
    },
    deleteRole () {
      const ids = this.multipleSelection.map(item => item.id)
      this.$api.deleteBatch('sysRole', ids).then(() => {
        this.reGetData()
      })
    },
    reGetData () {
      this.$refs.AppTable.clearSelection()
      this.$refs.AppTable.reGetData()
    },
    addRole () {
      this.$refs.editRoleDialog.show()
    },
    editRole (item) {
      this.$refs.editRoleDialog.edit(item)
    }
  },
  mounted () {
  }
}
</script>
